<template>
  <AdminView>
    <h1 class="title-1">Employés</h1>

    <div class="buttons-container">
      <div class="search">
        <Input
          title="Rechercher"
          v-model="search"
        />
      </div>

      <router-link :to="{ name: 'AddEmployee' }">
        <Button
          title="Ajouter"
          type="secondary"
        >
          <SVGPlus />
        </Button>
      </router-link>
    </div>

    <div class="employees-list">
      <div class="row employee employees-head">
        <div @click="sort('lastname')">Nom</div>
        <div @click="sort('firstname')">Prénom</div>
        <div @click="sort('email')">Email</div>
        <div>Création du compte</div>
        <div>Personas</div>
        <div>Agences</div>
      </div>

      <router-link v-for="(employee, i) in sortedEmployees" :key="i" :to="{ name: 'Employee', params: { id: employee.id } }" class="employee-link">
        <div class="row employee">
          <div>{{ employee.lastname }}</div>
          <div>{{ employee.firstname }}</div>
          <div>{{ employee.email }}</div>
          <div>{{ employee.accountCreation }}</div>
          <div>{{ employee.personas.join(', ') }}</div>
          <div>{{ employee.agencies.join(', ') }}</div>
        </div>
      </router-link>
    </div>
  </AdminView>
</template>

<script>
import AdminView from '@/components/AdminView'
import Input from '@/components/Forms/Fields/Input'
import Button from '@/components/Button'
import SVGPlus from '@/assets/icons/plus.svg'

export default {
  name: 'EmployeesList',
  components: {
    AdminView, Input, Button, SVGPlus
  },
  data () {
    return {
      search: '',
      sortBy: 'lastname',
      employees: [
        {
          id: 1,
          firstname: 'John',
          lastname: 'Doh',
          email: 'john.doe@test.com',
          accountCreation: '27/04/2021',
          personas: ['Compta', 'Facturation'],
          agencies: ['Agence Paris', 'Agence IdF']
        },
        {
          id: 2,
          firstname: 'Test',
          lastname: 'Test',
          email: 'test@test.com',
          accountCreation: '24/04/2021',
          personas: ['Compta', 'Facturation'],
          agencies: ['Agence Paris']
        },
        {
          id: 3,
          firstname: 'Test',
          lastname: 'Test',
          email: 'test@test.com',
          accountCreation: '22/04/2021',
          personas: ['Compta', 'Facturation'],
          agencies: ['Agence Paris', 'Agence IdF']
        },
        {
          id: 4,
          firstname: 'Test',
          lastname: 'Test',
          email: 'test@test.com',
          accountCreation: '03/05/2021',
          personas: ['Compta', 'Facturation'],
          agencies: ['Agence Paris', 'Agence IdF']
        }
      ]
    }
  },
  methods: {
    sort (type) {
      if (this.sortBy === type) {
        this.sortBy = '-' + type
      } else {
        this.sortBy = type
      }
    }
  },
  computed: {
    filteredEmployees () {
      return this.employees.filter(p =>
        p.firstname.toLowerCase().includes(this.search.toLowerCase()) || p.lastname.toLowerCase().includes(this.search.toLowerCase())
      )
    },
    sortedEmployees () {
      const order = this.sortBy.startsWith('-') ? -1 : 1
      const sortBy = order < 0 ? this.sortBy.substring(1) : this.sortBy

      return [...this.filteredEmployees].sort((a, b) => {
        return a[sortBy] > b[sortBy] ? 1 * order : -1 * order
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.buttons-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 40px 0;

  div {
    flex: 0;
  }
}

.employees-list{
  border-radius: 10px;
  overflow: hidden;
}
.employees-head{
  font-weight: 420;
  font-size: 1.2rem;
}

.employee-link{
  display: flex;
  transition: all 0.25s;
  
  &:nth-child(2n){
    background: $light-grey-color;
  }

  &:hover{
    background: rgba(240, 240, 240, 0.5);
  }
}

.employee{
  flex: 1;
  align-items: center;
  transition: all 0.25s;

  & > div{
    flex: 1;
    padding: 10px;
    line-height: 1.25rem;
  }
}
</style>
